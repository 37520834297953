<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="1000"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 11 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="8" :xs="24">
          <!-- 所属运营商 -->
          <a-form-item label="模板编号:">
            <div class="ud-text-secondary">
              {{ data.priceCode }}
            </div>
          </a-form-item>
          <!-- 厂家 -->
          <a-form-item label="模板状态:">
            <div class="ud-text-secondary">
              {{ data.state }}
            </div>
          </a-form-item>
          <!-- 型号 -->
          <a-form-item label="租金(元):">
            <div class="ud-text-secondary">{{ data.rent }}</div>
          </a-form-item>
          <!-- 机柜尺寸 -->
          <a-form-item label="押金(元):">
            <div class="ud-text-secondary">{{ data.deposit }}</div>
          </a-form-item>
          <a-form-item label="已签合同押金(元):">
            <div class="ud-text-secondary">{{ data.contractedDeposit }}</div>
          </a-form-item>
          <a-form-item label="丢失保障服务费(元):">
            <div class="ud-text-secondary">{{ data.lossFee }}</div>
          </a-form-item>
        </a-col>
        <a-col :sm="8" :xs="24">
          <!-- 仓位尺寸 -->
          <a-form-item label="折扣系数:">
            <div class="ud-text-secondary">
              {{ data.discountFactor }}
            </div>
          </a-form-item>
          <!-- 屏幕尺寸 -->
          <a-form-item label="计费周期:">
            <div class="ud-text-secondary">
              {{ data.cycle }}
            </div>
          </a-form-item>
          <!-- 仓位数 -->
          <a-form-item label="次数:">
            <div class="ud-text-secondary">
              {{ data.count }}
            </div>
          </a-form-item>
          <!-- 防水等级 -->
          <a-form-item label="所属品牌:">
            <div class="ud-text-secondary">
              {{ data.brandName }}
            </div>
          </a-form-item>
          <a-form-item label="所属型号:">
            <div class="ud-text-secondary">
              {{ data.model }}
            </div>
          </a-form-item>
            <a-form-item label="图片">
            <div class="ud-text-secondary">
              <img :src="data.pricePic" width="100" height="100">
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="8" :xs="24">
          <a-form-item label="所属运营商:">
            <div class="ud-text-secondary">
              {{ data.operatorName }}
            </div>
          </a-form-item>
          <a-form-item label="所属租赁点:">
            <div class="ud-text-secondary">
              {{ data.pointNames }}
            </div>
          </a-form-item>
          <a-form-item label="创建人:">
            <div class="ud-text-secondary">
              {{ data.creater }}
            </div>
          </a-form-item>
          <a-form-item label="创建时间:">
            <div class="ud-text-secondary">
              {{ data.createTime }}
            </div>
          </a-form-item>
           <a-form-item label="名称:">
            <div class="ud-text-secondary">
              {{ data.priceName }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'brandDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  methods: {
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
</style>
